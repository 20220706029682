.more_information {
  z-index: 2;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(66, 66, 66);
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 4px;
}


.container {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: linear-gradient(to right, rgb(72, 0, 91), black, rgb(0, 11, 85));
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  animation-delay: 0;
}

.information_title {
  font-size: 70px;
  margin: 50px 0 10px 0;
  text-align: center;
}

.information_intro {
  width: 80%;
  font-size: 17px;
  text-align: center;
}

.information_menu {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 45px;
}

.information_button {
  padding: 7px 3px;
  width: 33%;
  font-size: 14px;
  cursor: pointer;
  transition: .3s;
  border-radius: 5px;
  background: transparent;
  border: 1.5px solid white;
}

.information_button:hover {
  transform: translateY(-2px);
}



.info_outer_section {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  margin-top: 20px;
  width: 80%;
  align-items: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-bottom: 60px;
}

.info_section {
  box-shadow: 0 0 10px white;
  width: 85%;
  padding: 20px;
  border-radius: 10px;
  margin: 25px 0;
  background-color: rgba(0,0,0,.1);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  transition: .3s;
  animation-delay: 0;
}

.info_section:hover {
  width: 87%;
}

.outer_info_section {
  margin: 40px 0;
}

.info_title {
  font-weight: 800;
  margin-bottom: 15px;
}

.info_info {
  font-size: 14px;
  margin: 4px 0;
  width: 1400px;
}

.info_bold {
  font-weight: 800;
  color: rgb(0, 243, 8);
}

.contact_info {
  padding: 20px;
  font-size: 14px;
  text-align: center;
}

@keyframes color {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
  
}

@media screen and (max-width: 1150px) {
  .info_info {
    width: 600px;
  }
}

@media screen and (max-width: 850px) {
  .information_title {
    font-size: 55px;
  }

  .information_intro {
    font-size: 15px;
  }

  .information_button {
    font-size: 12px;
  }

  .info_info {
    font-size: 14px;
    width: 400px;
  }
}

@media screen and (max-width: 550px) {
  .info_info {
    width: 320px;
  }
}

@media screen and (max-width: 485px) {
  .info_info {
    width: 285px;
  }
}
